<template>
    <ion-page>
        <ion-content :scroll="false">
            <ion-toolbar
                style="--background: #03a9f4; color: white; --min-height: 36px"
                class="custom-toolbar"
            >
                <div class="row align-center">
                    <slot name="before">
                        <DxButton
                            icon="mdi mdi-chevron-left"
                            type="default"
                            @click="
                                () => {
                                    $router.push({ path: 'tabs/tab4' });
                                }
                            "
                        />
                    </slot>
                    <slot name="center">
                        <div class="grow text-xs-center">HƯỚNG DẪN SỬ DỤNG</div>
                    </slot>
                    <slot name="after">
                        <div class="shrink" :style="'opacity: 0'">
                            <DxButton
                                type="default"
                                icon="mdi mdi-chevron-left"
                                class="ml-1"
                            />
                        </div>
                    </slot>
                </div>
            </ion-toolbar>
            <ion-list>
                <ion-item
                    v-for="(item, index) in danhSachChucNang"
                    :key="index"
                    @click="MoTrangHuongDan(item.link)"
                    detail
                    button
                >
                    <ion-label>{{ item.text }}</ion-label>
                </ion-item>
            </ion-list>
        </ion-content>
    </ion-page>
</template>
<script>
import ThanhTieuDeVue from "../../../src/components/_Common/ThanhTieuDe";
import ToastContentVue from "../../../src/components/_Common/ToastContent";
import { DxList, DxToast } from "devextreme-vue";
import { IonPage, IonContent, IonItem, IonList, IonLabel } from "@ionic/vue";
import { DxButton } from "devextreme-vue";

export default {
    components: {
        ThanhTieuDeVue,
        ToastContentVue,
        DxList,
        DxToast,
        IonPage,
        IonContent,
        DxButton,
        IonItem,
        IonList,
        IonLabel,
    },
    props: {},
    data() {
        return {
            data: [],
            toast: { visible: false, message: "", type: "success" },
            danhSachChucNang: [
                {
                    id: 1,
                    text: "Quản lý lệnh cho lái xe",
                    link: this.$i18n.t("UrlHuongDan.QuanLyLenhChoLaiXe"),
                },
                {
                    id: 2,
                    text: "Bán vé cho lái xe",
                    link: this.$i18n.t("UrlHuongDan.BanVeChoLaiXe"),
                },
                {
                    id: 3,
                    text: "Quản lý lệnh cho doanh nghiệp",
                    link: this.$i18n.t("UrlHuongDan.QuanLyLenhChoDoanhNghiep"),
                },
                {
                    id: 4,
                    text: "Bán vé ủy thác cho nhân viên",
                    link: this.$i18n.t("UrlHuongDan.BanVeUyThacChoNhanVien"),
                },

                // {
                //     id: 2,
                //     text: "Thông báo",
                //     link: "/thong-bao",
                // },
                // {
                //     id: 3,
                //     text: "Lịch sử bán vé",
                //     link: "/Bao-Cao/lich-su-ban-ve",
                // },
                // {
                //     id: 4,
                //     text: "Danh sách phương tiện đã vận hành",
                //     link: "/danh-sach-phuong-tien-da-van-hanh",
                // },
                // {
                //     id: 5,
                //     text: "Cài đặt",
                //     link: "",
                // },
            ],
        };
    },
    computed: {},
    methods: {
        MoTrangHuongDan(name) {
            this.$router.push({
                path: "/huong-dan-su-dung/Xem-Chi-Tiet-Huong-Dan",
                query: { name: name },
            });
        },
    },
    created() {
        // this.getData();
    },
    mounted() {},
};
</script>
<style scoped>
>>> .dx-list-item-content {
    padding: unset !important;
}
</style>
<style lang='scss' scoped>
.dx-template-wrapper.dx-item-content.dx-list-item-content {
    border-bottom: 1px solid $border;
}
.thongbao-style {
    color: $text;
}
.hidden {
    background-color: rgb(3 169 244 / 10%) !important;
}
</style>
